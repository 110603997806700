/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { ThemeProvider } from 'styled-components';
import merge from 'lodash.merge';
import get from 'lodash.get';

import Header from "./header"
import "./layout.css"
import baseTheme from '../theme';
import GlobalStyle from "./globalStyle";
import Flex from "./flex";

const modes = [
  'light',
  'dark',
];

const getTheme = mode =>
  merge({}, baseTheme, {
    colors: get(baseTheme.colors.modes, mode, baseTheme.colors),
  })

const Layout = ({ activeMenu, children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const [mode, setMode] = useState(modes[0])
  const theme = getTheme(mode)

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Header siteTitle={data.site.siteMetadata.title} activeMenu={activeMenu} />
      <Flex
        w={1}
        flexDirection="column"
        alignItems="center"
      >
        <Flex
          flexDirection="column"
          maxWidth={1326}
          mx={[0, null, 54]}
          width="100%"
        >
          <main>{children}</main>
        </Flex>
      </Flex>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  activeMenu: PropTypes.string,
}

export default Layout
