import { Link as GatsbyLink } from "gatsby"
import styled from 'styled-components';
import {
  space,
  layout,
  typography,
  flexbox,
  background,
  border,
  position,
  color,
} from 'styled-system';
import { themeGet } from "@styled-system/theme-get";

const Link = styled(GatsbyLink)`
  ${space}
  ${color}
  ${layout}
  ${typography}
  ${flexbox}
  ${background}
  ${border}
  ${position}
  text-decoration: none;

  &:hover {
    color: ${themeGet('colors.secondary')}
  }
`;

export default Link;
