import { createGlobalStyle } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const GlobalStyle = createGlobalStyle`
  body {
    background: ${themeGet('colors.background')};
    font-family: ${themeGet('fontFamily.sansSerif')};
    color: ${themeGet('colors.text')};
  }
  h1 {
    color: ${themeGet('colors.text')};
  }
  h2 {
    color: ${themeGet('colors.primary')};
  }
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 140%;
    letter-spacing: 0.02em;
  }
`
export default GlobalStyle;
