const coral = '#F2696A';
const coralLight = '#F69292';
const turquoise = '#4CBFB4';
const turquoiseLight = '#DFF6F4';
const turquoiseDark = '#385552';
const charcoal = '#414141';
const white = '#FFFFFF';

const colors = {
  text: charcoal,
  primary: coral,
  secondary: turquoise,
  highlight: turquoiseLight,
  background: white,
  modes: {
    dark: {
      text: white,
      primary: coralLight,
      secondary: turquoise,
      highlight: turquoiseDark,
      background: charcoal,
    },
  },
};

const fontSizes = [
];

const breakpoints = Object.values({
  0: '40em', // 640px
  1: '52em', // 832px
  2: '64em', // 1024px
  3: '120em', // 1920px
});

const space = [0, 4, 8, 16, 32, 64];

const fontWeights = {
  extraLight: 200,
  light: 300,
  book: 400,
  medium: 500,
  bold: 700,
};

const borders = [
  0,
  '1px solid',
];

const letterSpacings = {
  normal: 'normal',
  large: '0.1em',
  xlarge: '0.2em',
};

const radii = Object.values({
  0: 0,
  1: 2,
  2: 4,
  3: 8,
});

const lineHeights = Object.values({
  0: 1,
  1: 1.125,
  2: 1.25,
  3: 1.5,
});

const fontFamily = {
  serif: 'serif',
  sansSerif: 'Roboto, sans-serif',
};



const fonts = {
  ...fontFamily,
};

const fadeInDelay = 75;
const transition = 500;

const Theme = {
  colors,
  fontSizes,
  breakpoints,
  space,
  fontWeights,
  borders,
  letterSpacings,
  radii,
  lineHeights,
  fonts,
  fontFamily,
  fadeInDelay,
  transition,
};

export default Theme;
