import styled from 'styled-components';
import {
  space,
  layout,
  typography,
  flexbox,
  background,
  border,
  position,
  color,
} from 'styled-system';

const Flex = styled.div`
  ${space}
  ${color}
  ${layout}
  ${typography}
  ${flexbox}
  ${background}
  ${border}
  ${position}
  box-sizing: ${props => props.boxSizing || 'border-box'};
`;

Flex.defaultProps = {
  display: 'flex',
};

export default Flex;
