import React from 'react';
import { Match } from "@reach/router";
import Flex from './flex';
import MenuLink from './menuLink';

const Menu = () => {
  return (
    <Flex>
      <Match path="/">
        {homePage => (
          <Match path="/case-studies/:item">
            {props => (
              <MenuLink
                isActive={(props.match || homePage.match) ? true : false}
                to="/"
                activeClassName={undefined}
              >
                Case Studies
              </MenuLink>
            )}
          </Match>
        )}
      </Match>
      <MenuLink to="/about-me">About Me</MenuLink>
      <MenuLink href="https://ivyksmart.myportfolio.com/print-branding-marketing">Project Archive</MenuLink>
      <MenuLink href="mailto:ivysaysouch@gmail.com">Say Hello</MenuLink>
    </Flex>
  );
};

export default Menu;
