import styled from 'styled-components';
import {
  space,
  layout,
  typography,
  flexbox,
  background,
  border,
  position,
} from 'styled-system';

const Img = styled.img`
  ${space}
  ${layout}
  ${typography}
  ${flexbox}
  ${background}
  ${border}
  ${position}
`;

export default Img;
