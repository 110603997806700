import React from 'react';
import Link from './link';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import {
  space,
  layout,
  typography,
  flexbox,
  background,
  border,
  position,
  color,
} from 'styled-system';

const StyledA = styled.a`
  ${space}
  ${color}
  ${layout}
  ${typography}
  ${flexbox}
  ${background}
  ${border}
  ${position}
  text-decoration: none;

  &:hover {
    color: ${themeGet('colors.secondary')}
  }
`;

const Component = ({ isActive, ...props }) => props.href
  ? <StyledA {...props} /> : <Link {...props} />;

const StyledLink = styled(Component)`
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-decoration: none;
  color: inherit;
  font-weight: bold;

  &.active {
    border-bottom: 2px solid;
    border-color: ${themeGet('colors.secondary')};
  }

  ${(props) => {
    return (props.isActive) ? {
      borderBottom: '2px solid',
      borderColor: themeGet('colors.secondary')(props),
    } : {};
  }}
`;
StyledLink.displayName = 'MenuLink';

const MenuLink = (props) => {
  return (
    <StyledLink
      color="text"
      activeClassName="active"
      partiallyActive
      px={8}
      mx={16}
      {...props}
    />
  );
};

export default MenuLink;
