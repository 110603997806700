import React from "react"
import logo from "../images/logo.svg"
import Flex from "./flex"
import Img from "./img"
import Link from "./link"
import Menu from "./menu"

const Header = () => (
  <header>
    <Flex
      mt={24}
      px={54}
      w={1}
      justifyContent="center"
    >
      <Flex
        maxWidth={1326}
        width={1}
        alignItems="center"
        flexDirection={['column', null, 'row']}
        justifyContent="space-between"
        borderBottom="1px solid"
        borderColor="primary"
      >
        <Link><Img src={logo} mb={0} /></Link>
        <Menu />
      </Flex>
    </Flex>
  </header>
)

export default Header
